<template>
  <div>
    <vue-element-loading
    :active="appLoading"
    spinner="bar-fade-scale"
    color="#4480C5"
    size="60"
    is-full-screen
  />
    <v-snackbar v-model="showSnackBar" color="primary" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap justify-center>
      <v-flex pt-5 xs11 sm11 md11 lg11 xl11>
        <!-- Header Section -->
        <v-layout wrap justify-start class="mt-5">
          <v-flex
            xs12
            sm4
            md4
            lg4
            xl4
            align-center
            pb-3
            mt-2
            class="headingChurch"
            >Live Telecast</v-flex
          >

          <v-flex xs12 sm6 md6 lg6 xl6>
            <v-text-field
              v-model="keyword" 
              class="text-field"
              append-icon="mdi-magnify"
              placeholder="Search by title"
              solo
              clearable
              dense
              flat
              outlined
              hide-details
              style="font-family: interregular"
            ></v-text-field>
          </v-flex>

          <v-flex
            xs12
            sm2
            md2
            lg2
            xl2
            pt-lg-0
            pt-md-0
            pt-sm-0
            pt-2
            text-end
            align-center
            ><v-btn
              class="churchbtn"
              elevation="0"
              color="blue"
              style="color: white"
              @click="dialog = true"
              >ADD LIVE
            </v-btn></v-flex
          >

          <v-dialog v-model="dialog" persistent max-width="800px">
            <AddLive @stepper="winStepper" />
          </v-dialog>
        </v-layout>

        <v-layout wrap justify-start pb-3 class="text-des">
          <v-flex
            xs12
            sm6
            md2
            lg2
            pr-lg-2
            pr-sm-2
            pr-md-2
            pr-0
            pt-lg-0
            pt-md-0
            pt-sm-0
            pt-2
          >
            <v-select
              :items="riteArray"
              v-model="rite"
              item-text="name"
              class="text-field"
              item-value="_id"
              placeholder="Rite"
              style="font-family: interregular"
              solo
              clearable
              dense
              flat
              outlined
              :hide-details="true"
            ></v-select>
          </v-flex>

          <v-flex
            xs12
            sm6
            md2
            lg2
            pr-lg-2
            pr-sm-0
            pr-md-2
            pr-0
            pt-lg-0
            pt-md-0
            pt-sm-0
            pt-2
          >
            <v-select
              :items="languageArray"
              v-model="language"
              item-text="languageName"
              item-value="_id"
              placeholder="Language"
              class="text-field"
              style="font-family: interregular"
              solo
              clearable
              dense
              flat
              :hide-details="true"
              outlined
            ></v-select>
          </v-flex>

          <v-flex
            xs12
            sm6
            md2
            lg2
            pr-lg-2
            pr-sm-2
            pr-md-2
            pt-lg-0
            pt-md-0
            pt-sm-2
            pt-2
            pr-0
          >
            <v-select
              v-model="selectedServicesNames"
              :items="servicesArray"
              placeholder="Telecast Type"
              class="text-field"
              style="font-family: interregular"
              solo
              clearable
              dense
              flat
              outlined
              :hide-details="true"
              item-text="name"
              item-value="_id"
            ></v-select>
          </v-flex>

          <v-flex
            xs12
            sm6
            md2
            lg2
            pr-lg-2
            pr-sm-0
            pr-md-2
            pr-0
            pt-lg-0
            pt-md-0
            pt-sm-2
            pt-2
          >
            <v-text-field
              v-model="date"
              class="text-field"
              placeholder="Select Date"
              :hide-details="true"
              readonly
              outlined
              flat
              dense
              solo
              clearable
              @click="showDatePicker = !showDatePicker"
              :append-icon="
                showDatePicker ? 'mdi-calendar-remove' : 'mdi-calendar'
              "
            ></v-text-field>
            <v-dialog
              v-model="showDatePicker"
              persistent
              max-width="290px"
              :style="{ 'z-index': 2000 }"
              @click:outside="showDatePicker = false"
            >
              <v-date-picker
                v-model="date"
                @input="updatedDate"
              ></v-date-picker>
            </v-dialog>
          </v-flex>
          <v-flex
            xs12
            sm6
            md2
            lg2
            pr-lg-2
            pr-sm-2
            pr-md-2
            pr-0
            pt-lg-0
            pt-md-0
            pt-sm-2
            pt-2
          >
            <v-text-field
              v-model="fromDate"
              placeholder="From Date"
              class="text-field"
              :hide-details="true"
              readonly
              solo
              outlined
              dense
              flat
              clearable
              @click="showFromDatePicker = !showFromDatePicker"
              :append-icon="
                showFromDatePicker ? 'mdi-calendar-remove' : 'mdi-calendar'
              "
            ></v-text-field>
            <v-dialog
              v-model="showFromDatePicker"
              persistent
              max-width="290px"
              :style="{ 'z-index': 2000 }"
              @click:outside="showFromDatePicker = false"
            >
              <v-date-picker
                v-model="fromDate"
                @input="updateFromDate"
              ></v-date-picker>
            </v-dialog>
          </v-flex>
          <v-flex
            xs12
            sm6
            md2
            lg2
            pr-lg-0
            pr-sm-0
            pr-md-0
            pr-0
            pt-lg-0
            pt-md-0
            pt-sm-2
            pt-2
          >
            <v-text-field
              v-model="toDate"
              placeholder="To Date"
              class="text-field"
              :hide-details="true"
              readonly
              outlined
              flat
              solo
              dense
              clearable
              @click="showToDatePicker = !showToDatePicker"
              :append-icon="
                showToDatePicker ? 'mdi-calendar-remove' : 'mdi-calendar'
              "
            ></v-text-field>
            <v-dialog
              v-model="showToDatePicker"
              persistent
              max-width="290px"
              :style="{ 'z-index': 2000 }"
              @click:outside="showToDatePicker = false"
            >
              <v-date-picker
                v-model="toDate"
                @input="updateToDate"
              ></v-date-picker>
            </v-dialog>
          </v-flex>
        </v-layout>
        <!-- Card Section -->
        <v-layout wrap justify-center>
          <v-flex xs12>
            <v-card>
              <v-card-text>
                <!-- Church Detail Card Section -->
                <v-layout wrap justify-start v-if="videos.length > 0">
                  <v-flex
                    xs12
                    sm6
                    md4
                    lg4
                    v-for="(video, index) in videos"
                    :key="index"
                    class="d-flex mb-3"
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs12>
                        <v-card
                          class="video-card"
                          elevation="1"
                          style="
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                          "
                        >
                          <iframe
                            :key="video.url"
                            :src="
                              'https://www.youtube.com/embed/' +
                              video.url +
                              '?rel=0'
                            "
                            width="100%"
                            height="200px"
                            scrolling="no"
                            contain
                            frameborder="0"
                            allowtransparency="true"
                            allowfullscreen="true"
                          ></iframe>
                          <v-flex text-start pb-0 style="flex-grow: 1">
                            <v-layout wrap justify-start>
                              <v-flex xs10 py-2 pt-4 pl-3>
                                <span class="video-title">
                                  {{ video.title }}
                                </span>
                              </v-flex>
                              <v-flex
                                xs2
                                class="d-flex align-center justify-end"
                              >
                                <v-menu offset-y>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon>
                                      <v-icon color="black"
                                        >mdi-dots-vertical</v-icon
                                      >
                                    </v-btn>
                                  </template>
                                  <v-list>
                                    <v-list-item @click="editSlider(video)">
                                      <v-icon small color="primary"
                                        >mdi-pencil</v-icon
                                      >
                                      <v-list-item-content
                                        class="pl-3 addChurch"
                                        >Edit</v-list-item-content
                                      >
                                    </v-list-item>
                                    <v-list-item
                                      @click="openDeleteDialog(video)"
                                    >
                                      <v-icon color="red" small
                                        >mdi-delete</v-icon
                                      >
                                      <v-list-item-content
                                        class="pl-3 addChurch"
                                        >Delete</v-list-item-content
                                      >
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                                <v-flex px-1>
                                  <v-btn
                                    :style="{
                                      backgroundColor: videoSwitch[video._id]
                                        ? '#3fcf25 '
                                        : 'grey',
                                      borderRadius: '50%',
                                      height: '15px',
                                      width: '15px',
                                      minWidth: '15px',
                                      padding: '0',
                                    }"
                                    @click="toggleStatus(video)"
                                  >
                                  </v-btn>
                                </v-flex>
                              </v-flex>
                            </v-layout>
                            <v-flex pt-1>
                              <v-divider></v-divider>
                            </v-flex>

                            <!-- <v-flex class="live-des pl-3 mt-3">
                              <span
                                ><v-icon size="15px" color="primary">
                                  mdi-calendar
                                </v-icon>
                                {{ formatDate(video.startTime) }}</span
                              >
                              <span class="pl-2">
                                <v-icon size="15px" color="primary">
                                  mdi-clock
                                </v-icon>
                                {{
                                  formatTimes(video.startTime, video.endTime)
                                }}
                              </span>
                            </v-flex> -->
                            <v-flex class="live-des pl-3 mt-3">
                              <span>
                                <v-icon size="15px" color="primary">
                                  mdi-calendar-check
                                </v-icon>
                                {{ formatDate(video.startTime) }} <!-- Start Date -->
                              </span>
                              <span class="pl-2">
                                <v-icon size="15px" color="primary">
                                  mdi-clock
                                </v-icon>
                                {{ extractTimeFromUTC(video.startTime) }} <!-- Start Time -->
                              </span>
                            </v-flex>
                            <v-flex class="live-des pl-3 mt-3">
                              <span>
                                <v-icon size="15px" color="primary">
                                  mdi-calendar-minus
                                </v-icon>
                                {{ formatDate(video.endTime) }} <!-- End Date -->
                              </span>
                              <span class="pl-2">
                                <v-icon size="15px" color="primary">
                                  mdi-clock-outline
                                </v-icon>
                                {{ extractTimeFromUTC(video.endTime) }} <!-- End Time -->
                              </span>
                            </v-flex>
                            <v-flex class="live-des pl-4 mt-1">
                              <span>
                                {{ video.rite.name }},
                                {{ video.language.languageName }},
                                {{ video.telecastType }}</span
                              >
                            </v-flex>

                            <!-- <v-switch 
                            v-model="videoSwitch[video._id]"
                            @change="updateStatus(video)"
                            style="font-family:interregular;height:5px;width:5px;"
                          >
                            <template v-slot:label>
                              <span :style="{ color: video.status === 'Active' ? 'black' : 'grey', fontSize: '14px' }">
                                {{ video.status === 'Active' ? 'Active' : 'Inactive' }}
                              </span>
                            </template>
                          </v-switch> -->
                            <!-- <v-btn
                          :style="{
                            backgroundColor: videoSwitch[video._id] ? 'green' : 'grey',
                            borderRadius: '50%',
                            height: '20px',
                            width: '20px',
                            minWidth: '20px',
                            padding: '0',
                          }"
                          @click="toggleStatus(video)"
                        >
                        </v-btn> -->
                          </v-flex>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>

                <v-layout v-else>
                  <v-flex
                    text-center
                    style="font-size: medium"
                    class="addChurch"
                  >
                    No Live Telecast Found
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
            <v-layout wrap justify-center pt-2 v-if="videos">
              <v-flex xs12 v-if="videos.length > 0">
                <v-pagination
                  size="small"
                  color="primary white--text"
                  v-model="page"
                  :length="Pagelength"
                  circle
                >
                </v-pagination>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <!-- edit dialog -->
    <v-dialog v-model="editdialog" max-width="800px">
      <v-card rounded="lg">
        <v-card-title>
          <span class="editbox">EDIT LIVE TELECAST</span>
          <v-spacer></v-spacer>
        </v-card-title>
    
        <v-card-text>
          <v-form ref="editForm" v-model="formValid">
            <v-layout wrap justify-start px-2>
              <v-flex xs6 pr-2>
                <span class="label"> Rite </span>
                <v-select
                  class="pt-2 text-des"
                  style="font-size: 14px"
                  outlined
                  dense
                  :items="riteArray"
                  :rules="[(v) => !!v || 'Rite is required']"
                  v-model="editingitem.rite"
                  item-text="name"
                  item-value="_id"
                ></v-select>
              </v-flex>
              <v-flex xs6 pr-2>
                <span class="label"> Language </span>
                <v-select
                  class="pt-2 text-des"
                  style="font-size: 14px"
                  outlined
                  dense
                  :items="languageArray"
                  item-text="languageName"
                  item-value="_id"
                  v-model="editingitem.language"
                  :rules="[(v) => !!v || 'Language is required']"
                ></v-select>
              </v-flex>
    
              <v-flex xs6 pr-2>
                <span class="label"> Title </span>
                <v-text-field
                  class="pt-2 text-des"
                  style="font-size: 14px"
                  v-model="editingitem.title"
                  outlined
                  dense
                  :rules="[(v) => !!v || 'Title is required']"
                ></v-text-field>
              </v-flex>
    
              <v-flex xs6 pr-2>
                <span class="label"> Services </span>
                <v-select
                  class="pt-2 text-des"
                  style="font-size: 14px"
                  outlined
                  dense
                  :items="servicesArray"
                  v-model="editingitem.telecastType"
                  item-text="name"
                  item-value="name"
                  :rules="[(v) => !!v || 'Services is required']"
                ></v-select>
              </v-flex>
              <v-flex xs6 pr-2>
                <span class="label"> URL </span>
                <v-text-field
                  class="text-des pt-2"
                  style="font-size: 14px"
                  v-model="editingitem.url"
                  outlined
                  dense
                  :rules="[(v) => !!v || 'URL is required']"
                ></v-text-field>
              </v-flex>
    
        
              <v-flex xs6 pr-2>
                <span class="label">Start Date</span>
                <v-menu
                  ref="menuStartDate"
                  v-model="menuStartDate"
                  :close-on-content-click="false"
                  :return-value.sync="startDate"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startDate"
                      outlined clearable
                      readonly class="text-des pt-2"
           @change="clearEndTimeAndDate" 
                      dense
                      v-bind="attrs"
                      v-on="on" :rules="[(v) => !!v || 'Start Date is required']"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    no-title
                    :min="nowDate"
                    scrollable
                    @change="$refs.menuStartDate.save(startDate)"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
    
              <v-flex xs6 pr-2>
                <span class="label">Start Time</span>
                <v-select
                  v-model="editingitem.startTime"
                  :items="getAvailableTimes(startDate)"  
                 
                  outlined clearable class="text-des pt-2"
                  dense
                  :rules="[(v) => !!v || 'Start time is required']"
                  @change="clearEndTimeAndDate" 
                ></v-select>
              </v-flex>
              
              
    
              <!-- End Date and Time -->
              <v-flex xs6 pr-2>
                <span class="label">End Date</span>
                <v-menu
                  ref="menuEndDate"
                  v-model="menuEndDate"
                  :close-on-content-click="false"
                  :return-value.sync="endDate"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="endDate" clearable
                      outlined
                      readonly class="text-des pt-2"
           :rules="[(v) => !!v || 'End date is required']"
                      dense
                      v-bind="attrs"   :disabled="!startDate || !editingitem.startTime" 
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="endDate"
                    no-title
                    :min="nowDate"
                    scrollable
                    @change="$refs.menuEndDate.save(endDate)"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
    
              <v-flex xs6 pr-2>
                <span class="label">End Time</span>
                <v-select
               
                v-model="editingitem.endTime"
                :items="endTimeOptions" clearable
            
                outlined class="text-des pt-2"
                dense
                :rules="[(v) => !!v || 'End time is required']"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
    
        <v-divider></v-divider>
    
        <v-card-actions class="py-4 justify-end headline grey lighten-2">
          <v-btn outlined color="grey" text @click="closeDialog()"
            ><span style="color: black">Cancel</span>
          </v-btn>
          <v-btn color="primary" @click="edit(editingitem._id)">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    

    <v-dialog v-model="deletedialog" max-width="600">
      <v-card class="dialog-card">
        <v-card-title class="grey lighten-2 d-flex justify-center">
          <v-icon color="red" size="32">mdi-alert</v-icon>
          <span class="ml-2">Confirm Deletion</span>
        </v-card-title>
        <v-card-text class="py-5 text-center text-des">
          <div class="body-1">
            Are you sure you want to delete this Live Telecast?
            <br />
            This action <strong>cannot be undone</strong>.
          </div>
          <v-divider class="my-3"></v-divider>
          <v-row class="pt-2" no-gutters>
            <v-col>
              <img
                src="@/assets/images/live1.png"
                width="70px"
                height="70px"
                class="dialog-icon"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pa-2">
          <v-btn
            style="font-family: interBold; font-size: 13px; color: white"
            color="#cf3a45"
            @click="deletedialog = false"
            >Cancel</v-btn
          >
          <v-btn
            style="font-family: interBold; font-size: 13px; color: white"
            color="blue darken-1"
            @click="confirmDelete"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AddLive from "./addLive";
import axios from "axios";
export default {
  components: {
    AddLive,
  },
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 5000,
      formValid: false,
      msg: "",
      deletedialog: false,
      riteArray: [],
      videoSwitch: {},
      languageArray: [],
      rite: "",
      language: "",
      servicesArray: [{ name: "HolyMass" }, { name: "Adoration" }, { name: "Motivational Videos" }],
      selectedServicesNames: [],
      dialog: false,
      editdialog: false,
      //editingitem: [],
      videos: [],
      keyword: "",
      startTime: "",
      endTime: "",
      //checkInDate: new Date().toISOString().substr(0, 10), // Today's date in 'YYYY-MM-DD' format
      defaultTimeRange: { start: "00:00", end: "17:00" },
      //menuCheckIn: false,
      currentHour: new Date().getHours(),
      currentMinute: new Date().getMinutes(),
      videoToDelete: null,
      fromDate: null, // Initial from date in YYYY-MM-DD format
      toDate: null, // Initial to date in YYYY-MM-DD format
      date: null,
      formattedFromDate: "",
      formattedToDate: "",
      formattDate: "",
      showFromDatePicker: false,
      showToDatePicker: false,
      showDatePicker: false,



      editingitem: {
        rite: "",
        language: "",
        title: "",
        telecastType: "",
        url: "",
        startTime: "",
        endTime: "",
      },
      startDate: "", // For the start date
    endDate: "", // For the end date
    menuStartDate: false, // Controls start date menu visibility
    menuEndDate: false, // Controls end date menu visibility
      nowDate: new Date().toISOString().substr(0, 10),
      endTimeOptions: [],
      













      Pagelength: "",

      page: 1,
      limit: 6,
    };
  },
  mounted() {
    this.getData(); // Fetch data when component mounts
    this.getLanguage();
    this.getRite();
    
  },

  watch: {
    page() {
      this.getData();
    },
    keyword() {
      this.getData();
    },
    checkInDate() {
      // Clear checkInTime and checkOutTime when checkInDate changes
      this.startTime = null;
      this.endTime = null;
      this.updateEndTimeOptions();
      this.page = 1;
    },
    rite() {
      this.page = 1;
      (this.toDate = ""),
        (this.fromDate = ""),
        (this.date = ""),
        (this.language = ""),
        (this.selectedServicesNames = ""),
        this.getData();
    },
    language() {
      this.page = 1;
      (this.toDate = ""), (this.fromDate = ""), (this.date = "");

      (this.selectedServicesNames = ""), this.getData();
    },
    selectedServicesNames() {
      (this.toDate = ""), (this.fromDate = ""), (this.date = "");
      this.getData();
      this.page = 1;
    },
    
    date() {
      // this.formattDate = newDate;
      // Fetch data whenever toDate changes
      // this.toDate="",
      // this.fromDate=""

      this.page = 1;
      this.getData();
      // this.toDate = "";
      // this.fromDate = "";
    },
    fromDate() {
      // this.formattedFromDate = newDate;
      //this.date="",
   
      this.page = 1;

      // Fetch data whenever fromDate changes
      this.getData();
    },
    toDate() {
      // this.formattedToDate = newDate;
      // this.rite="",
      // this.language="",
      //   this.selectedServicesNames="",
      this.page = 1;
      // Fetch data whenever toDate changes
      this.getData();
    },
   
  },

  computed: {
   
    // nowDate() {
    //   const now = new Date();
    //   return now.toISOString().substr(0, 10);
    // },

    formattedStartTime() {
      return this.editingitem.startTime
        ? this.formatTime(this.editingitem.startTime)
        : "";
    },
    formattedEndTime() {
      return this.editingitem.endTime
        ? this.formatTime(this.editingitem.endTime)
        : "";
    },
  },
  methods: {
    clearEndTimeAndDate() {
    // Clear the end date and end time
    this.endDate = '';   // Reset the end date
    this.editingitem.endTime = '';  // Reset the end time

    // Optionally, update end time options if you need to refresh any time-related restrictions
    this.updateEndTimeOptions();
  },
    //   updateStatus(video) {
    //   // Toggle status
    //   const newStatus = this.videoSwitch[video._id] ? 'Active' : 'Inactive';
    //   axios.post('/enable/disable/live/telecast', {
    //     id: video._id,
    //     status: newStatus === 'Active'
    //   }, {
    //     headers: {
    //       token: localStorage.getItem("token")
    //     }
    //   })
    //   .then(response => {
    //     // Optionally update the local state or handle success
    //     video.status = newStatus;
    //     console.log('Status updated successfully:', response.data);
    //   })
    //   .catch(err => {
    //     console.error('Error updating status:', err);
    //     // Handle error (e.g., revert local state or show a message)
    //   });
    // },

    toggleStatus(video) {
      // Toggle status
      const newStatus = !this.videoSwitch[video._id] ? "Active" : "Inactive";
      axios
        .post(
          "/enable/disable/live/telecast",
          {
            id: video._id,
            status: newStatus === "Active",
          },
          {
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          // Optionally update the local state or handle success
          this.videoSwitch[video._id] = newStatus === "Active";
          video.status = newStatus;
          console.log("Status updated successfully:", response.data);
        })
        .catch((err) => {
          console.error("Error updating status:", err);
          // Handle error (e.g., revert local state or show a message)
        });
    },
    updatedDate(date) {
      this.date = date;
      this.formattDate = date;
      this.showDatePicker = false;
      this.getData(); // Fetch data when toDate changes
      this.fromDate = "";
    this.toDate = "";
    },
    updateFromDate(date) {
      this.fromDate = date;
      this.formattedFromDate = date;
      this.showFromDatePicker = false;
      this.getData(); // Fetch data when fromDate changes
      this.toDate = "";
      this.date = "";
    },
    updateToDate(date) {
      this.toDate = date;
      this.formattedToDate = date;
      this.showToDatePicker = false;
      this.getData(); // Fetch data when toDate changes
      this.date = "";
    },
   

    checkDate() {
      this.$forceUpdate();

      this.$nextTick(() => {
        // Clear selectedTime when selectedDate changes
        this.editingitem.startTime = null;
        this.editingitem.endTime = null;

        // Now, format date and times into timestamps using methods
        const formattedStartTime = this.formatTimestamp(
          this.checkInDate,
          this.editingitem.startTime
        );
        const formattedEndTime = this.formatTimestamp(
          this.checkInDate,
          this.editingitem.endTime
        );

        // Set these formatted values to the relevant data properties if needed
        this.editingitem.startTime = formattedStartTime;
        this.editingitem.endTime = formattedEndTime;
      });
    },

    formatTimestamp(date, time) {
      if (!date || !time) return null;
      const formattedDate = date.replace(/-/g, "-"); // Ensure date is in 'YYYY-MM-DD' format
      const formattedTime = time.padStart(5, "0"); // Ensure time is in 'HH:MM' format
      return `${formattedDate} ${formattedTime}:00`;
    },

  //   clearCheckOutTime() {
  //   if (this.endTime && this.endTime < this.startTime) {
  //     this.endTime = null;
  //   }

  //   // Format end time into timestamp
  //   this.formattedEndTime = this.formatTimestamp(this.checkInDate, this.endTime);
  // },
  

    formatDateTime(date, time) {
      const dateObj = new Date(date);
      const timeParts = time.split(':'); // Assuming time is in HH:MM format
      dateObj.setHours(timeParts[0], timeParts[1]); // Set hours and minutes
      return dateObj.toISOString(); // Return as ISO string or any desired format
    },


    getCurrentTime() {
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, "0");
      const minutes = now.getMinutes().toString().padStart(2, "0");
      return `${hours}:${minutes}`;
    },




    winStepper(item) {
      if (item.ref == "addLive") {
        this.dialog = item.dialog;
        this.getData();
      }
    },
    closeDialog() {
      this.editdialog = false;
      this.getData();
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/get/admin/live/telecasts",

        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          page: this.page,
          limit: this.limit,
          rite: this.rite,
          language: this.language,
          keyword: this.keyword,
          telecastType: this.selectedServicesNames,
          fromDate: this.fromDate,
          toDate: this.toDate,
          date: this.date,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.videos = response.data.data;
          this.videoSwitch = this.videos.reduce((acc, video) => {
            acc[video._id] = video.status === "Active";
            return acc;
          }, {});
          // this.pages = response.data.pages;
          // this.totalData = response.data.count;
          this.Pagelength = Math.ceil(response.data.totalLength / this.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getRite() {
      this.appLoading = true;
      axios({
        url: "/get/rite/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.riteArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getLanguage() {
      this.appLoading = true;
      axios({
        url: "/get/language/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.languageArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },


    formatTimes(startTime, endTime) {
    return `${this.extractTimeFromUTC(startTime)} - ${this.extractTimeFromUTC(endTime)}`;
},
extractTimeFromUTC(utcDateString) {
    const timePart = utcDateString.slice(11, 16); // Extract "HH:MM" part from the UTC string
    const hours = timePart.slice(0, 2);
    const minutes = timePart.slice(3, 5);
    const amPm = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    let hour12 = hours % 12;
    hour12 = hour12 ? hour12 : 12; // the hour '0' should be '12'

    return `${hour12}:${minutes} ${amPm}`;
},
    // convertUTCToLocal(utcDateString) {
    //   const utcDate = new Date(utcDateString);
    //   let hours = utcDate.getUTCHours();
    //   const minutes = utcDate.getUTCMinutes().toString().padStart(2, "0");
    //   const amPm = hours >= 12 ? "PM" : "AM";

    //   // Convert hours to 12-hour format
    //   hours = hours % 12;
    //   hours = hours ? hours : 12; // the hour '0' should be '12'

    //   // Use backticks for template literals
    //   return `${hours}:${minutes} ${amPm}`;
    // },

    // convertUTCToLocal1(utcDateString) {
    //   const utcDate = new Date(utcDateString);
    //   const hours = utcDate.getHours().toString().padStart(2, "0");
    //   const minutes = utcDate.getMinutes().toString().padStart(2, "0");
    //   return `${hours}:${minutes}`;
    // },

    // formatTimes(startTime, endTime) {
    //   // Use backticks for template literals
    //   return `${this.convertUTCToLocal(startTime)} - ${this.convertUTCToLocal(
    //     endTime
    //   )}`;
    // },

    // formatDate(item) {
    //   var dt = new Date(item);
    //   var day = dt.getDate();
    //   var year = dt.getFullYear();
    //   dt = dt.toString();
    //   var strTime = day + " " + dt.slice(4, 7) + " " + year;
    //   return strTime;
    // },
    formatDate(item) {
    var dt = new Date(item);
    var day = dt.getUTCDate();
    var month = dt.getUTCMonth(); // 0-based, so January is 0
    var year = dt.getUTCFullYear();
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var strTime = day + " " + monthNames[month] + " " + year;
    return strTime;
},

    formatTime(datetime) {
      const time = new Date(datetime);
      const hours = time.getUTCHours().toString().padStart(2, "0");
      const minutes = time.getUTCMinutes().toString().padStart(2, "0");
      return `${hours}:${minutes}`;
    },

    // Combine the date and time to the format required by the backend
    combineDateTime(date, time) {
      if (!date || !time) return null;
      return new Date(`${date}T${time}:00.000Z`).toISOString();
    },

    // When editing, display the form with existing data
    
    resetForm() {
      this.editingitem = {
        rite: "",
        language: "",
        title: "",
        telecastType: "",
        url: "",
        startTime: "",
        endTime: "",
      };
      this.checkInDate = new Date().toISOString().substr(0, 10);
      this.endTimeOptions = [];
    },

    editSlider(video) {
  // Clone the video data
  this.editingitem = { ...video };

  // Extract and format the start and end times
  this.editingitem.startTime = this.formatTime(video.startTime);
  this.editingitem.endTime = this.formatTime(video.endTime);

  // Extract the date portions from startTime and endTime
  this.startDate = video.startTime.split("T")[0]; // Date portion
  this.endDate = video.endTime.split("T")[0];

  this.editdialog = true;

  // Update the end time options based on the selected start time
  this.updateEndTimeOptions();
},
// Update end time options based on the selected start time and date
updateEndTimeOptions() {
  if (this.startDate === this.endDate) {
    // Filter end times to only show times greater than the selected start time
    const selectedStartTime = this.editingitem.startTime;

    // Get all available times for the end date and filter them
    this.endTimeOptions = this.getAvailableTimes(this.endDate).filter((time) => {
      return time > selectedStartTime;
    });

    // Check if the filtered times array is empty (if no valid end time is greater than start)
    if (this.endTimeOptions.length === 0) {
      this.msg = "No available end times. Please choose a valid start time.";
      this.showSnackBar = true;
    }
  } else {
    // If the dates are different, show all available times
    this.endTimeOptions = this.getAvailableTimes(this.endDate);
  }
},

// Get available times based on date
getAvailableTimes(date) {
  const isToday = date === this.nowDate; // Check if the selected date is today
  const currentHour = new Date().getHours(); // Get current hour
  const maxHour = 23; // Maximum hour in a day
  const availableTimes = [];
  const startHour = isToday ? currentHour : 0; // Start hour depends on whether it's today

  for (let hour = startHour; hour <= maxHour; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const formattedHour = hour < 10 ? `0${hour}` : hour;
      const formattedMinute = minute === 0 ? "00" : minute;
      availableTimes.push(`${formattedHour}:${formattedMinute}`);
    }
  }

  return availableTimes;
},

edit() {
  const form = this.$refs.editForm;

  // Validate the form before submission
  if (!form.validate()) {
    this.msg = "Please fill out all required fields correctly.";
    this.showSnackBar = true;
    return;
  }

  // Combine start date and start time to form a valid ISO string for the start time
  const startDateTimeStr = `${this.startDate}T${this.editingitem.startTime}:00`;
  const startDateTime = new Date(startDateTimeStr);

  // Combine end date and end time to form a valid ISO string for the end time
  const endDateTimeStr = `${this.endDate}T${this.editingitem.endTime}:00`;
  const endDateTime = new Date(endDateTimeStr);

  // Check if the start date and end date are the same
  if (this.startDate === this.endDate) {
    // Ensure start time is less than end time
    if (startDateTime >= endDateTime) {
      this.msg = "Start time must be less than End time on the same day.";
      this.showSnackBar = true;
      return; // Exit the method if validation fails
    }
  }

  // Check if start and end times are correctly formed
  console.log('Start DateTime:', startDateTime.toISOString());
  console.log('End DateTime:', endDateTime.toISOString());

  // Prepare the updated item to be sent to the backend
  const updatedItem = {
    id: this.editingitem._id,  // Include the ID for the item being updated
    title: this.editingitem.title,
    url: this.editingitem.url,
    rite: this.editingitem.rite._id,  // Use rite's _id
    language: this.editingitem.language._id,  // Use language's _id
    telecastType: this.editingitem.telecastType,
    startTime: startDateTime.toISOString(),  // Send start time as ISO string
    endTime: endDateTime.toISOString(),  // Send end time as ISO string
  };

  // Send the updated data using a POST request
  axios({
    url: "/update/live/telecast",  // Ensure this is your correct backend endpoint
    method: "POST",
    data: updatedItem,
    headers: {
      token: localStorage.getItem("token"),
    },
  })
  .then((response) => {
    if (response.data.status) {
      this.editdialog = false;  // Close the dialog on success
      this.msg = response.data.msg;
      this.showSnackBar = true;
      this.getData();  // Fetch the updated data
    } else {
      this.msg = response.data.msg;  // Show the error message
      this.showSnackBar = true;
    }
  })
  .catch((error) => {
    console.error(error);  // Log error for debugging
    this.ServerError = true;  // Handle server errors
    this.msg = "Error updating live telecast.";  // Show failure message
    this.showSnackBar = true;
  });
},


    openDeleteDialog(video) {
      this.itemToDelete = video;
      this.deletedialog = true;
    },
    confirmDelete() {
      if (this.itemToDelete) {
        this.deleteItem(this.itemToDelete);
      }
      this.deletedialog = false;
    },
    deleteItem(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/delete/live/telecast",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.active-btn {
  background-color: green;
  color: white;
}

.inactive-btn {
  background-color: grey;
  color: white;
}

.video-card {
  margin: 10px;
  cursor: pointer;
}

.video-title {
  font-size: 14px;
  font-family: interbold;
  text-transform: uppercase;
}

.video-duration {
  color: grey;
  font-size: 12px;
}

.card-header {
  background-color: #f5f5f5;
  padding: 16px;
  font-family: intermedium;
  border-bottom: 1px solid #e0e0e0;
}

.centered-text {
  text-align: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: white;
  opacity: 0.8;
  border-radius: 4px;
}

.dialog-card {
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  position: relative;
}

.dialog-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

.dialog-icon {
  animation: pulse 1s infinite alternate;
}

.dialog-button {
  min-width: 120px;
}

@keyframes pulse {
  from {
    transform: scale(1);
    opacity: 0.7;
  }

  to {
    transform: scale(1.1);
    opacity: 1;
  }
}
</style>