<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="primary" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-card rounded="lg">
      <v-card-title>
        <span class="editbox">ADD LIVE TELECAST</span>
        <v-spacer></v-spacer>
        <v-btn color="red" icon @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form ref="addForm" v-model="formValid">
          <v-layout wrap justify-start px-2>
            <v-flex xs6 pr-2 pt-2>
              <span class="label">Rite</span>
              <v-select
                class="pt-2 text-des"
                style="font-size: 14px"
                outlined
                dense
                :hide-details="true"
                :items="riteArray"
                v-model="rite"
                item-text="name"
                item-value="_id"
                 :rules="[(v) => !!v || 'Rite is required']"
              ></v-select>
            </v-flex>
            <v-flex xs6 pr-2 pt-2>
              <span class="label">Language</span>
              <v-select
                class="pt-2 text-des"
                style="font-size: 14px"
                outlined
                dense :hide-details="true"
                :items="languageArray"
                item-text="languageName"
                item-value="_id"
                v-model="language"
                 :rules="[(v) => !!v || 'Language is required']"
              ></v-select>
            </v-flex>
            <v-flex xs6 pr-2 pt-2>
              <span class="label"> Title </span>
              <v-text-field
                class="pt-2 text-des"
                style="font-size: 14px"
                v-model="title"
                outlined :hide-details="true"
                dense
                :rules="[(v) => !!v || 'Title is required']"
              ></v-text-field>
            </v-flex>

            <v-flex xs6 pr-2 pt-2>
              <span class="label"> Services </span>
              <v-select
                class="pt-2 text-des"
                style="font-size: 14px"
                outlined
                dense :hide-details="true"
                :items="servicesArray"
                v-model="selectedServicesNames"
                item-text="name"
                item-value="name"
                :rules="[(v) => !!v || 'Service is required']"
              ></v-select>
            </v-flex>
            <v-flex xs6 pr-2 pt-2>
              <span class="label"> URL </span>
              <v-text-field
                class="pt-2 text-des"
                style="font-size: 14px"
                v-model="url"
                outlined :hide-details="true"
                dense
                 :rules="[(v) => !!v || 'URL is required']"
             
              ></v-text-field>
            </v-flex>

            <!-- Start Date and Time -->
            <v-flex xs6 pr-2 pt-2>
              <span class="label">Start Date</span>
              <v-menu
                class="pt-2 text-des"
                style="font-size: 14px"
                ref="menuStartDate" 
                v-model="menuCheckIn"
                :close-on-content-click="false"
                :return-value.sync="startDate"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="pt-2 text-des"
                    style="font-size: 14px"
                    v-model="checkInDate"
                    outlined :hide-details="true"
                    readonly
                    label="Start Date"
                    color="primary"
                    dense
                   
                     :rules="[(v) => !!v || 'Start date is required']"
                    clearable
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                v-model="checkInDate" @input="menuCheckIn = false"
                  no-title
                  :min="nowDate"
                  scrollable
                  @change="$refs.menuStartDate.save(startDate)"
                 
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </v-flex>

            <v-flex xs6 pr-2 pt-2>
              <span class="label">Start Time</span>
              <v-select
              class="pt-2 text-des"
                style="font-size: 14px"
                v-model="startTime" :items="getAvailableTimes(checkInDate)"  :disabled="!checkInDate"
                clearable :hide-details="true"
                outlined
                dense
                @change="updateEndTimeOptions"
                 :rules="[(v) => !!v || 'Start time is required']"
              ></v-select>
            </v-flex>

            <!-- End Date and Time -->
            <v-flex xs6 pr-2 pt-2>
              <span class="label">End Date</span>
              <v-menu
                class="pt-2 text-des"
                style="font-size: 14px"
                ref="menuEndDate"
                v-model="menuCheckOut" 
                :close-on-content-click="false"
                :return-value.sync="endDate"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="pt-2 text-des"
                    style="font-size: 14px"
                    v-model="endDate" :disabled="!checkInDate || !startTime"
                    outlined
                    readonly  
                    label="End Date"
                    color="primary" :hide-details="true"
                    dense
                   :rules="[(v) => !!v || 'End date is required']"
                    clearable
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                 v-model="endDate" @input="menuCheckOut = false"
                  no-title
                  :min="checkInDate"
                  scrollable
                  @change="$refs.menuEndDate.save(endDate)"
                 
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </v-flex>

            <v-flex xs6 pr-2 pt-2>
              <span class="label">End Time</span>
              <v-select
                class="pt-2 text-des"
                style="font-size: 14px"
                v-model="endTime"  clearable
      :items="endTimeOptions"   :disabled="!checkInDate || !startTime"
                
                outlined :hide-details="true"
                dense
                 :rules="[(v) => !!v || 'End time is required']"
              ></v-select>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="py-4 justify-end headline grey lighten-2">
        <v-btn outlined color="grey" text @click="closeDialog()"
          ><span style="color: black">Cancel</span>
        </v-btn>
        <v-btn color="primary" @click="itemadd()">Save</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
//import { VueEditor } from "vue2-editor";
// import ImageComp from "@/components/Common/singleImages";
import axios from "axios";
export default {
  components: {
    //ImageComp,
    // VueEditor,
  },
  data() {
    return {
      showSnackBar: false,
      timeout: 5000,
      msg: "",
      riteArray: [],
      languageArray: [],
      rite: "",
      language: "",
      servicesArray: [{ name: "HolyMass" }, { name: "Adoration" }, { name: "Motivational Videos" }],
      selectedServicesNames: [],
      date: null,
      dialog: false,
      title: null,
      url: null,
      defaultTimeRange: { start: "00:00", end: "17:00" },
      currentHour: new Date().getHours(),
      currentMinute: new Date().getMinutes(),
      checkInDate: new Date().toISOString().substr(0, 10), // Today's date in 'YYYY-MM-DD' format
      endDate: null, // End date will be null initially
      menuCheckIn: false,
      menuCheckOut: false, // For end date picker
      endTimeOptions: [],
      nowDate: new Date().toISOString().substr(0, 10), 
      isEndDateEnabled: false,
      isEndTimeEnabled: false,
      startDate: null,
      startTime: "",
      endTime: "",
      menuStartDate: false,
      menuEndDate: false,
      formValid: false,
    };
  },

  mounted() {
    this.getLanguage();
    this.getRite();
  },
  watch: {
    checkInDate() {
    this.startTime = null;
    this.endTime = null;
    this.updateEndTimeOptions();
  },
  endDate() {
    this.endTime = null;
    this.updateEndTimeOptions();
  },
  startTime() {
    this.updateEndTimeOptions();
  },
  },

  computed: {
    timepickerOptions() {
      const isToday =
        this.checkInDate === new Date().toISOString().split("T")[0];
      const startTime = isToday
        ? this.getCurrentTime()
        : this.defaultTimeRange.start;
      const endTime = this.defaultTimeRange.end;
      console.log(endTime);
      return [[startTime.slice(0, 2), endTime.slice(0, 2)]];
    },

    //     nowDate() {
    //   const now = new Date();
    //   return now.toISOString().substr(0, 10);
    // },

    formattedStartTime() {
      return this.formatTimestamp(this.checkInDate, this.startTime);
    },
    formattedEndTime() {
      return this.formatTimestamp(this.checkInDate, this.endTime);
    },
  },
  methods: {
    checkDate(dateType) {
  if (dateType === "startDate") {
    // Only reset startTime and endTime if the new date is selected
    if (this.checkInDate !== this.startDate) {
      this.startTime = null; // Resetting the start time when date changes
    }
    this.endTime = null; // Clear end time when start date changes
    this.updateEndTimeOptions();
  } else if (dateType === "endDate") {
    // Clear only the end time when end date changes
    this.endTime = null;
    this.updateEndTimeOptions();
  }
},


    formatTimestamp(date, time) {
      if (!date || !time) return null;
      const formattedDate = date.replace(/-/g, "-"); // Ensure date is in 'YYYY-MM-DD' format
      const formattedTime = time.padStart(5, "0"); // Ensure time is in 'HH:MM' format
      return `${formattedDate} ${formattedTime}:00`;
    },
    getRite() {
      this.appLoading = true;
      axios({
        url: "/get/rite/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.riteArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getLanguage() {
      this.appLoading = true;
      axios({
        url: "/get/language/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.languageArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    clearCheckOutTime() {
      if (this.endTime && this.endTime < this.startTime) {
        this.endTime = null;
      }

      // Format end time into timestamp
      this.formattedEndTime = this.formatTimestamp(
        this.checkInDate,
        this.endTime
      );
    },
    updateEndTimeOptions() {
  console.log('Selected startTime:', this.startTime);
  if (this.checkInDate === this.endDate) {
    if (!this.startTime) {
      this.endTimeOptions = this.getAvailableTimes(this.endDate);
      return;
    }
    const [startHour, startMinute] = this.startTime.split(":").map(Number);
    const availableTimes = this.getAvailableTimes(this.endDate);

    this.endTimeOptions = availableTimes.filter((time) => {
      const [hour, minute] = time.split(":").map(Number);
      return hour > startHour || (hour === startHour && minute > startMinute);
    });
  } else {
    this.endTimeOptions = this.getAvailableTimes(this.endDate);
  }
},

  getAvailableTimes(date) {
    const isToday = date === this.nowDate;
    const currentHour = new Date().getHours();
    const maxHour = 23;
    const availableTimes = [];

    const startHour = isToday ? currentHour : 0;

    for (let hour = startHour; hour <= maxHour; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const formattedHour = hour < 10 ? `0${hour}` : hour;
        const formattedMinute = minute === 0 ? "00" : minute;
        availableTimes.push(`${formattedHour}:${formattedMinute}`);
      }
    }

    return availableTimes;
  },

    getCurrentTime() {
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, "0");
      const minutes = now.getMinutes().toString().padStart(2, "0");
      return `${hours}:${minutes}`;
    },
    closeDialog() {
      this.$emit("stepper", {
        ref: "addLive",
        dialog: false,
      });
    },

    saveDialog() {
      this.$emit("stepper", {
        ref: "addLive",
        dialog: false,
      });
    },
    updateDate(showDate) {
      this.showDate = showDate;
      this.dialog = false; // Close the dialog after selecting the date
    },

    itemadd() {

      const form = this.$refs.addForm;
      if (!form.validate()) {
    this.msg = "Please fill out all required fields correctly.";
    this.showSnackBar = true;
    return;
  }
  // Validate required fields before sending
  if (!this.startTime || !this.checkInDate || !this.endDate) {
    console.error("Start time, Start date, and End date are required!");
    return; // Stop execution if validation fails
  }

  const data = {
    title: this.title,
    url: this.url,
    startTime: this.formatTimestamp(this.checkInDate, this.startTime),
    endTime: this.formatTimestamp(this.endDate, this.endTime),
    rite: this.rite,
    language: this.language,
    telecastType: this.selectedServicesNames,
  };

  axios({
    url: "/add/new/live/telecast",
    method: "POST",
    data: data,
    headers: { token: localStorage.getItem("token") },
  })
    .then((response) => {
      // if (response.data.status) {
      //   this.dialog = false;
      //   this.resetForm(); // Ensure this function resets all fields properly
      // } 
      if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.dialog = false;
            this.title = null;
            this.url = null;
            this.startTime = null;
            this.endTime = null;
            this.rite = null;
            this.language = null;
            this.selectedServicesNames = null;
            this.saveDialog();
            
           // this.$router.push({ name: 'liveTelecast' });
           window.location.reload();
            this.$router.push({
                path: "/liveTelecast",
                
                query: {
                  msg: response.data.msg,
                },
              });
          } 
      else {
        this.msg = response.data.msg;
        this.showSnackBar = true;
      }
    })
    .catch((err) => console.error(err));
},

    resetForm() {
      this.title = null;
      this.url = null;
      this.startDate = null;
      this.endDate = null;
      this.startTime = null;
      this.endTime = null;
      this.rite = null;
      this.language = null;
      this.selectedServicesNames = null;
    },
  },
};
</script>